export const createCompilationDto = {
  title: "",
  subTitle: "",
  url: "",
  author: "",
  seoTitle: "",
  seoHeader: "",
  isIndexable: true,
  isPublished: true,
  tickers: [],
  files: [],
  imageUrls: [""]
};

Object.freeze(createCompilationDto);
