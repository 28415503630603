<template>
  <div>
    <h2 class="mb-4">Новая подборка</h2>

    <v-sheet class="pa-4" elevation="3">
      <compilation-form
        :compilation="compilation"
        :loading="loading"
        @change-field="changeField"
        @submit="createCompilations"
        @clear="clear"
      />
    </v-sheet>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { ApiValidationError } from "~/core/api-validation-error";
import { createCompilationDto } from "~/libs/compilation/create-compilation-dto";
import { UpdateCompilationDto } from "~/libs/compilation/update-compilation-dto";

import CompilationForm from "~/components/compilations/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      loading: false,

      compilation: { ...createCompilationDto }
    };
  },
  methods: {
    async createCompilations() {
      this.loading = true;

      try {
        await this.$axios.$post("compilations", UpdateCompilationDto.create(this.compilation));

        this.clear();

        this.$snackbar.success("Подборка создана");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.compilation)).toHtml();

        this.$snackbar.error("Не удалось создать подборку: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.compilation = { ...createCompilationDto };
    },
    changeField(payload) {
      this.commitChange(this.compilation, payload);
    }
  },
  components: {
    CompilationForm
  }
};
</script>
